import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { useCakeSelector } from 'app/rootReducer';
import SignUpBonusBanner from 'common/containers/SignUpBonusBanner/SignUpBonusBanner';
import { isLoggedInSelector } from 'user/userSelector';
import Variables from 'Variables.styles';
import { getVoucherState } from 'voucher/VoucherSelector';
import { fetchPromotion } from 'referral/promotionCampaignSlice';
import FaceItBanner from 'voucher/FaceItBanner';
import RestrictBannerByCountryPromo from 'views/components/RestrictBannerByCountryPromo';
import { promotionCampaignDataSelector } from '../../../referral/referralPromoSelector';
import AccountVerificationBanner from './components/AccountVerificationBanner/AccountVerificationBanner';

const useStyles = createUseStyles({
  bannerBackground: {
    backgroundColor: '#F4F3F7',
  },
  bannerWrapper: {
    composes: 'container',
    display: 'grid',
    gap: '16px',
    paddingTop: Variables.medium,
  },
});

interface PathConfig {
  path: string;
  urlParamsToHideBanner?: string[];
  mustBeLoggedIn?: boolean; // if undefined, show banner for both true/false
}

interface ConditionalBannerProps {
  children: React.ReactNode;
  pathConfigs?: PathConfig[];
  allPathMustBeLoggedIn?: boolean;
}

const ConditionalBanner = (props: ConditionalBannerProps) => {
  const { children, pathConfigs, allPathMustBeLoggedIn } = props;
  const isLoggedIn = useCakeSelector(isLoggedInSelector);
  const location = useLocation();
  const [toHide, setToHide] = useState(true);

  const processBannerConfig = useCallback(() => {
    setToHide(true);
    const pathConfig = pathConfigs.find(item => item.path === location.pathname);
    if (pathConfig) {
      setToHide(false);
    }

    const isLocalLoginNotMatch = pathConfig?.mustBeLoggedIn !== undefined && ((pathConfig.mustBeLoggedIn && !isLoggedIn) || (!pathConfig.mustBeLoggedIn && isLoggedIn));
    if (isLocalLoginNotMatch) {
      setToHide(true);
    }

    if (pathConfig?.urlParamsToHideBanner) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      const paramList = Object.keys(params);
      for (let i = 0; i < paramList.length; i += 1) {
        if (pathConfig.urlParamsToHideBanner.includes(paramList[i])) {
          setToHide(true);
        }
      }
    }
  }, [location.pathname, pathConfigs, isLoggedIn]);

  const isLoginNotMatch = allPathMustBeLoggedIn !== undefined && ((allPathMustBeLoggedIn && !isLoggedIn) || (!allPathMustBeLoggedIn && isLoggedIn));

  useEffect(() => {
    if (pathConfigs) {
      processBannerConfig();
    } else if (isLoginNotMatch) {
      setToHide(true);
    } else {
      setToHide(false); // show all paths if no config
    }
  }, [location.pathname, pathConfigs, processBannerConfig, isLoginNotMatch]);

  if (toHide) {
    return null;
  }
  return <>{children}</>;
};

const SIGNUP_BONUS_BANNER_CONFIG: PathConfig[] = [
  {
    path: '/register',
    urlParamsToHideBanner: ['ref', 'promo'],
    mustBeLoggedIn: false,
  },
];

const HeaderBanners = () => {
  const classes = useStyles();
  const location = useLocation();
  const { details: userDetails } = useCakeSelector(state => state.user);
  const { showWelcomeBonusVisuals } = useCakeSelector(promotionCampaignDataSelector);
  const { voucherCode } = useCakeSelector(getVoucherState);
  const dispatch = useDispatch();
  const bannerWrapperRef = useRef<HTMLDivElement>(null);

  const showSignupBanner = showWelcomeBonusVisuals && voucherCode === '';

  useEffect(() => {
    dispatch(fetchPromotion());
  }, [dispatch]);

  return (
    <>
      <AccountVerificationBanner location={location} />
      <div className={classes.bannerBackground}>
        <div ref={bannerWrapperRef} className={bannerWrapperRef?.current?.firstChild ? classes.bannerWrapper : ''}>
          <RestrictBannerByCountryPromo />
          <FaceItBanner />
          {showSignupBanner && <ConditionalBanner pathConfigs={SIGNUP_BONUS_BANNER_CONFIG}><SignUpBonusBanner isLoggedIn={!!userDetails} /></ConditionalBanner>}
        </div>
      </div>
    </>
  );
};

export default HeaderBanners;
