import { LiquidityMiningPair } from '@cakedefi/cake-sdk/schema';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'app/rootReducer';
import BigNumber from 'bignumber.js';
import formatPercentage from 'utils/formatPercentage';
import { Lesson } from './data/learnEarnContent';
import { getIsNotComingSoon } from './learnEarnUtils';

export const getLearnEarnState = (state: RootState) => state.learnEarn;

export const getUserQuizzesInfo = createSelector(getLearnEarnState, s => s.userQuizzesInfo);
export const getCurrentModal = createSelector(getLearnEarnState, s => s.currentModal);
export const getCurrentLessonStack = createSelector(getLearnEarnState, s => s.currentLessonStack);
export const getCurrentLessonIndex = createSelector(getLearnEarnState, s => s.currentLessonIndex);
export const getCurrentLessonPartIndex = createSelector(getLearnEarnState, s => s.currentLessonPartIndex);
export const getUserQuizInput = createSelector(getLearnEarnState, s => s.userQuizInput);

export const getCurrentLessonStackId = createSelector(getLearnEarnState, s => s.currentLessonStack?.id);

const getUserDetails = (state: RootState) => state.user.details;
export const userHasEligibleLELessons = createSelector(getLearnEarnState, getUserQuizzesInfo, (state, userQuizzedInfo) => !state.isLoading && userQuizzedInfo.some(quiz => quiz.isEligible && !quiz.isAllQuizQuestionPaid));

export const getIsNonKycOrNonLoggedIn = createSelector(
  getUserDetails,
  (userDetails): boolean => {
    if (!userDetails) {
      return true;
    }
    if (userDetails.kycFullStatus !== 'APPROVED') {
      return true;
    }
    return false;
  },
);
export const isUserLoggedInKycAndEligible = createSelector((state: RootState) => state.user, (userState) => {
  if (!userState.details) {
    // user is not logged in or no user details. means user just playing around in the app, they are able to see claimable lessons
    return true;
  }

  if (userState.details) {
    if (!userState.accessRights?.promos?.access && userState.accessRights?.promos?.reasons?.includes('CountryRestriction')) {
      // if user has promos restriction, then then should be L only.
      return false;
    }

    if (userState.details.kycFullStatus !== 'APPROVED') {
      // users see LE and not just L, so in a way, we are enticing them to complete their KYC quickly
      return true;
    }

    if (userState.details.kycFullStatus === 'APPROVED') {
      // user kyc is approved, so they should be able to see LE
      return true;
    }
  }

  return false;
});
export const getIsStackEligibleToEarn = (stackId?: string) => createSelector(
  getUserQuizzesInfo,
  getUserDetails,
  getCurrentLessonStackId,
  (userQuizzesInfo, userDetails, currentLessonStackId): boolean => {
    const inputStackId = stackId || currentLessonStackId;
    if (userDetails) {
      const userQuizInfo = userQuizzesInfo?.find(item => item.id === inputStackId);
      return userQuizInfo?.isEligible;
    }
    return true;
  },
);

export const getUserClaimedStatus = (stackId?: string) => createSelector(
  getUserQuizzesInfo,
  getCurrentLessonStackId,
  (userQuizzesInfo, currentLessonStackId): string => {
    const inputStackId = stackId || currentLessonStackId;
    if (!userQuizzesInfo) return null;
    const userQuizInfo = userQuizzesInfo.find(item => item.id === inputStackId);
    if (!userQuizInfo) return null;
    return userQuizInfo.claimStatus;
  },
);

export const getCanCallClaimApi = (stackId: string) => createSelector(
  getUserDetails,
  getIsStackEligibleToEarn(),
  getUserQuizzesInfo,
  (userDetails, isStackEligibleToEarn, userQuizzesInfo): boolean => {
    const userQuizInfo = userQuizzesInfo?.find(item => item.id === stackId);
    return userDetails && isStackEligibleToEarn && !userQuizInfo?.claimStatus;
  },
);

export const getFullyClaimedStatus = (stackId: string) => createSelector(
  getIsStackEligibleToEarn(stackId),
  (isStackEligibleToEarn): boolean => {
    const isNotComingSoon = getIsNotComingSoon(stackId);
    return !isStackEligibleToEarn && isNotComingSoon;
  },
);

export const getLessonData = createSelector(
  getCurrentLessonStack,
  getCurrentLessonIndex,
  (lessonStack, lessonIndex): Lesson | null => {
    if (!lessonStack) return null;
    return lessonStack.lessons.find(lesson => lesson.lessonIndex === lessonIndex);
  },
);

export enum CardStatus {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_STARTED = 'NOT_STARTED',
}

export const getStackStatus = (stackId: string) => createSelector(
  getLearnEarnState,
  getUserQuizzesInfo,
  getUserDetails,
  (state, userQuizzesInfo, userDetails): CardStatus => {
    const { userSavedProgressBrowser } = state;
    const userQuizInfo = userQuizzesInfo.find(item => item.id === stackId);
    if (!userQuizInfo) return CardStatus.NOT_STARTED;

    const { isAllQuizQuestionDone, isAllQuizQuestionPaid, quizQuestionDoneIds, quizQuestionPaidIds } = userQuizInfo;
    const hasBrowserDataSaved = Object.keys(userSavedProgressBrowser).includes(stackId);

    if (userDetails?.kycFullStatus === 'APPROVED' && userQuizInfo?.isEligible) {
      if (isAllQuizQuestionPaid) return CardStatus.COMPLETED;
      if (!isAllQuizQuestionPaid && (quizQuestionPaidIds.length > 0 || quizQuestionDoneIds.length > 0 || hasBrowserDataSaved)) return CardStatus.IN_PROGRESS;
      return CardStatus.NOT_STARTED;
    }

    if (isAllQuizQuestionDone) return CardStatus.COMPLETED;
    if (!isAllQuizQuestionDone && (quizQuestionDoneIds.length > 0 || hasBrowserDataSaved)) return CardStatus.IN_PROGRESS;
    return CardStatus.NOT_STARTED;
  },
);

export const getPendingPayout = (stackId: string) => createSelector(
  getUserQuizzesInfo,
  getUserDetails,
  (userQuizzesInfo, userDetails): number | null => {
    if (userDetails) {
      const userQuizInfo = userQuizzesInfo?.find(item => item.id === stackId);
      return userQuizInfo?.pendingPayout;
    }
    return null;
  },
);

export const getCurrentLessonData = createSelector(
  getLessonData,
  getCurrentLessonIndex,
  getCurrentLessonPartIndex,
  (lessonData, lessonIndex, partIndex) => {
    if (!lessonData) return {};

    const partData = lessonData.parts.find(part => part.partIndex === partIndex);
    const nextPartText = `${partIndex + 1}`;
    const isFirstLessonFirstPart = lessonIndex === 1 && partData.partIndex === 1;
    const isLastLesson = lessonData.parts.length === partIndex;

    return {
      partData,
      nextPartText,
      isFirstLessonFirstPart,
      isLastLesson,
    };
  },
);

export const getPreloadImages = createSelector(
  getLessonData,
  getCurrentLessonStack,
  (lessonData, lessonStack) => {
    if (!lessonData) return [];

    const { welcomeImage } = lessonStack;
    const currentLessonImages = lessonData.parts.map(part => part.image);
    const nextLessonImages = (() => {
      const nextLessonData = lessonStack.lessons.find(item => item.lessonIndex === lessonData.lessonIndex + 1);
      if (nextLessonData) {
        return nextLessonData.parts.map(part => part.image);
      }
      return [];
    })();

    const imagesToPreload = [welcomeImage, ...currentLessonImages, ...nextLessonImages];
    return imagesToPreload;
  },
);

export const getCurrentQuizData = createSelector(
  getLessonData,
  getUserQuizInput,
  (lessonData, userQuizInput) => {
    if (!lessonData) return {};
    const quizData = lessonData.quiz;
    return {
      quizData,
      userQuizInput,
    };
  },
);

export const getCurrentResultData = createSelector(
  getCurrentLessonStack,
  getLessonData,
  getCurrentLessonIndex,
  getUserQuizInput,
  getUserQuizzesInfo,
  getUserDetails,
  (lessonStack, lessonData, lessonIndex, userQuizInput, userQuizzesInfo, userDetails) => {
    if (!lessonStack) return {};

    const quizQuestion = lessonData.quiz.question;
    const selectedQuizOptionInfo = (() => {
      if (userQuizInput) {
        return lessonData.quiz.options.find(item => item.option === userQuizInput);
      }
      return lessonData.quiz.options.find(item => item.option === lessonData.quiz.answer);
    })();

    const { id: quizId, coinId, rewardType } = lessonStack;
    const currentLessonNumber = lessonIndex;
    const { quizQuestionId, rewardInUsd } = lessonData;

    const showPopupPayoutMessage = (() => {
      const userQuizInfo = userQuizzesInfo.find(item => item.id === lessonStack.id);
      if (userDetails?.kycFullStatus === 'APPROVED' && userQuizInfo?.isEligible) {
        return !userQuizInfo?.quizQuestionPaidIds.includes(lessonData.quizQuestionId) || false;
      }
      return false;
    })();

    const explanation = selectedQuizOptionInfo?.explanation;

    const nextLessonNumber = (() => {
      const nextLessonData = lessonStack.lessons.find(item => item.lessonIndex === lessonData.lessonIndex + 1);
      if (nextLessonData) {
        return nextLessonData.lessonIndex;
      }
      return null;
    })();

    const isQuizCorrectAnswer = (() => {
      if (userQuizInput) {
        return userQuizInput === lessonData.quiz.answer;
      }
      return true;
    })();

    const isLastQuizAndResult = (() => {
      const lastQuizQuestionId = lessonStack.lessons[lessonStack.lessons.length - 1].quizQuestionId;
      return lessonData.quizQuestionId === lastQuizQuestionId;
    })();

    return {
      quizQuestion,
      selectedQuizOptionInfo,
      currentLessonNumber,
      quizId,
      coinId,
      rewardType,
      quizQuestionId,
      showPopupPayoutMessage,
      explanation,
      nextLessonNumber,
      isQuizCorrectAnswer,
      isLastQuizAndResult,
      rewardInUsd: rewardInUsd.toFixed(2),
    };
  },
);

export const getQuizStatus = createSelector(
  getCurrentLessonStack,
  getCurrentLessonIndex,
  getUserQuizzesInfo,
  getUserDetails,
  (lessonStack, lessonIndex, userQuizzesInfo, userDetails) => {
    if (!lessonStack) return {};
    if (!userQuizzesInfo) return {};

    const lessonData = lessonStack.lessons.find(item => item.lessonIndex === lessonIndex);
    const quizCorrectAnswer = lessonData.quiz.answer;

    const userQuizInfo = userQuizzesInfo.find(item => item.id === lessonStack.id);
    const isAbleToTakeQuiz = (() => {
      if (!userDetails) {
        return true;
      }
      if (userDetails.kycFullStatus === 'APPROVED' && userQuizInfo?.isEligible && !userQuizInfo?.isAllQuizQuestionPaid) {
        return !userQuizInfo.quizQuestionPaidIds.includes(lessonData.quizQuestionId);
      }
      return !userQuizInfo.quizQuestionDoneIds.includes(lessonData.quizQuestionId);
    })();

    return {
      isAbleToTakeQuiz,
      quizCorrectAnswer,
    };
  },
);

export const getDynamicPercentageRates = createSelector(
  (state: RootState) => state.liquidityMining,
  (state: RootState) => state.staking,
  (liquidityMining, staking): {
    btcDfiLiquidityMiningRate: string;
    dfiStakingShareReturns: string;
    dfiCoinReturns: string;
    highestApyAcrossLmAndStaking: string;
  } => {
    const { liquidityMiningPairs } = liquidityMining;
    const { shares } = staking;

    let btcDfiLiquidityMiningRate = '-';
    if (liquidityMiningPairs?.length) {
      const btcDfiLiquidityMiningPair = liquidityMiningPairs.find(pairData => pairData.id === 'BTC-DFI');
      btcDfiLiquidityMiningRate = btcDfiLiquidityMiningPair?.apr ? formatPercentage(btcDfiLiquidityMiningPair.apr) : '-';
    }

    let dfiStakingShareReturns = '-';
    if (shares?.length) {
      const dfiStakingShare = shares.find(pairData => pairData.id === 'DFI');
      dfiStakingShareReturns = dfiStakingShare?.stakingApy ? formatPercentage(dfiStakingShare.stakingApy) : '-';
    }

    let dfiCoinReturns = '-';
    if (shares?.length) {
      const dfiCoin = shares.find(coinData => coinData.id === 'DFI');
      if (dfiCoin?.stakingApy) {
        const apy = new BigNumber(dfiCoin.stakingApy);
        dfiCoinReturns = formatPercentage(apy.isLessThan(0) ? new BigNumber(0) : apy, 2);
      }
    }

    const getMaxApyShares = (sharesData) => sharesData.map(({ stakingApy }) => stakingApy).filter(Boolean);
    const getMaxApyLm = (lmPairsData: LiquidityMiningPair[]) => lmPairsData
      .filter(({ primaryCoinCategory, secondaryCoinCategory }) => secondaryCoinCategory === 'CRYPTOCURRENCY' && primaryCoinCategory === 'CRYPTOCURRENCY')
      .map(({ apr }) => Number(apr))
      .filter(Boolean);

    let highestApyAcrossLmAndStaking = '-';
    if (shares?.length || liquidityMiningPairs?.length) {
      const maxApyShares = shares ? getMaxApyShares(shares) : [];
      const maxApyLm = liquidityMiningPairs ? getMaxApyLm(liquidityMiningPairs) : [];
      const maxApy = Math.max(...maxApyShares, ...maxApyLm).toString();
      highestApyAcrossLmAndStaking = Number.isNaN(maxApy) ? '-' : formatPercentage(maxApy);
    }

    return {
      btcDfiLiquidityMiningRate,
      dfiStakingShareReturns,
      dfiCoinReturns,
      highestApyAcrossLmAndStaking,
    };
  },
);

export const learnAndEarnDisplaySelector = createSelector(
  isUserLoggedInKycAndEligible,
  userHasEligibleLELessons,
  (isEligible, hasSlot) => {
    if (isEligible && hasSlot) {
      return 'learnAndEarn';
    }
    return 'learn';
  },
);
