import { CakeEliteOperation, TxOperation } from '../lib/customType';

const getTextWithLMPair = (baseText: string, tx /* TODO: should be Transaction instance */) => (
  tx && tx.LiquidityMiningPairId ? `${baseText} ${tx.LiquidityMiningPairId}` : baseText);

const TxReadableMap: Record<TxOperation | CakeEliteOperation, (tx) => string> = {
  NA_WITHDRAWAL: () => 'Withdrawal',
  NA_DEPOSIT: () => 'Deposit',
  NA_NODE_REWARD: () => 'Staking reward',
  EXIT_SPROD: () => 'Lending reward',
  NA_FEE_PAID: () => 'Withdrawal fee',
  NA_CUSTOM: () => 'Custom transaction by the Bake team',
  SH_CUSTOM: () => 'Custom transaction by the Bake team',
  NA_TRANSFER_RCVD: () => 'Bonus/Airdrop',
  SH_TRANSFER_RCVD: () => 'Entry staking wallet: Bonus/Airdrop',
  SH_SPROD_DFI_REWARD: () => 'Entry staking wallet: Lending DFI Bonus',
  SH_SPROD_DFI_CONFECTIONERY_REWARD: () => 'Entry staking wallet: Confectionery Lending DFI Bonus',
  NA_COINSWAP_RCVD: () => 'Swapped in',
  NA_COINSWAP_SENT: () => 'Swapped out',
  NA_REFERRAL_REWARD_RCVD: () => 'Referral reward',
  NA_LM_REWARD: (tx) => getTextWithLMPair('Liquidity mining reward', tx),
  NA_LM_DFI_REWARD: (tx) => getTextWithLMPair('Liquidity mining reward', tx),
  NA_LM_CONSUME: (tx) => getTextWithLMPair('Add liquidity', tx),
  NA_LM_RECOVER: (tx) => getTextWithLMPair('Remove liquidity', tx),
  NA_LM_RECOVER_FEE_PAID: (tx) => getTextWithLMPair('Remove liquidity fee', tx),
  NA_CONFECTIONERY_SWAP_SENT: () => 'Used for 50% discount',
  NA_CONFECTIONERY_SWAP_RCVD: () => 'Claimed for 50% discount',
  SH_SIGNUP_BONUS_RCVD: () => 'Entry staking wallet: Signup bonus',
  SH_SIGNUP_TIER0_BONUS_RCVD: () => 'Entry staking wallet: Signup tier 0 bonus',
  SH_SIGNUP_TIER0_BONUS_RETURN_SENT: () => 'Signup tier 0 bonus returned',
  SH_REFERRER_SIGNUP_BONUS_RCVD: () => 'Entry staking wallet: Referral signup bonus',
  NA_ADMIN_OTC_SENT: () => 'OTC',
  NA_ADMIN_OTC_RCVD: () => 'OTC',
  NA_FREEZER_STAKING_BONUS: () => 'Freezer staking bonus',
  NA_FREEZER_LIQUIDITY_MINING_BONUS: () => 'Freezer liquidity mining bonus',
  NA_5_YEAR_FREEZER_REWARD: () => '5 years freezer reward',
  NA_10_YEAR_FREEZER_REWARD: () => '10 years freezer reward',
  MINT_SHARES: (tx) => getTextWithLMPair('Added liquidity', tx),
  BURN_SHARES: (tx) => getTextWithLMPair('Removed liquidity', tx),
  NA_DEPOSIT_FEE_PAID: () => 'Address creation fee',
  NA_REIMBURSEMENT_RCVD: () => 'Fee reimbursed',
  SH_FREEZER_PROMO_BONUS_RCVD: () => 'Entry staking wallet: Freezer promotion bonus',
  NA_PROMO_BONUS_RCVD: () => 'Promotion bonus',
  SH_KYC_COMPLETED_BONUS_RCVD: () => 'KYC completed bonus',
  NA_DEPOSIT_FEE_REIMBURSEMENT_RCVD: () => 'Gas fee reimbursed',
  SH_PROMO_BONUS_RCVD: () => 'Entry staking wallet: Promotion bonus',
  NA_DEX_SWAP: () => 'Withdrew for swap',
  NA_DEX_SWAP_FEE_PAID: () => 'Paid swap fee',
  NA_MKT_SENT: () => 'Entry staking wallet',
  NA_MKT_RCVD: () => 'Exit staking wallet',
  NA_MKT_FEE_PAID: () => 'Exit staking wallet fee',
  NA_ADD_COLLATERAL: () => 'Add collateral',
  NA_REMOVE_COLLATERAL: () => 'Remove collateral',
  NA_TAKE_LOAN: () => 'Borrow asset',
  NA_LOAN_FEE_PAID: () => 'Pay borrowing fees',
  NA_REPAY_LOAN: () => 'Repay Borrowed Assets',
  CO_LIQUIDATE_COLLATERAL: () => 'Collateral liquidated',
  LO_REPAY_LIQUIDATION: () => 'Repay Borrowed Assets by liquidation',
  LO_INTEREST_PAID: () => 'Pay borrowing interest',
  LO_INTEREST_RCVD: () => 'Loan interest received',
  NA_DFI_GESTURE_RCVD: () => 'DFI Gesture Received',
  NA_EARN_ENTER: () => 'Entered Earn',
  NA_EARN_EXIT: () => 'Exited Earn',
  NA_EARN_INSURANCE_RCVD: () => 'Adjusted Earn entry',
  EN_EARN_REWARD: () => 'Earn reward',
  TOKEN_SPLIT_IN: () => 'Token split ingoing',
  TOKEN_SPLIT_OUT: () => 'Token split outgoing',
  SH_CONVERT_ETH_RCVD: () => 'Converted csETH to ETH Staking Shares',
  NA_CONVERT_CSETH_RCVD: () => 'Converted ETH Staking Shares to csETH',
  VOTING_FEE_RCVD: () => 'Rewards from DeFiChain voting',
  NA_ENTER_YIELD_VAULT_SENT: () => 'Entered YieldVault',
  NA_EXIT_YIELD_VAULT_RCVD: () => 'Exited YieldVault',
  NA_YIELD_VAULT_REWARD: () => 'YieldVault reward',
  NA_STAKING_REWARD_BOOSTER_RCVD: () => 'Received ELITE reward booster from Staking',
  NA_LM_DFI_REWARD_BOOSTER_RCVD: (tx) => getTextWithLMPair('Received ELITE liquidity mining reward booster for', tx),
  NA_5_YEAR_FREEZER_REWARD_BOOSTER_RCVD: () => 'ELITE 5 years freezer reward booster',
  NA_10_YEAR_FREEZER_REWARD_BOOSTER_RCVD: () => 'ELITE 10 years freezer reward booster',
  NA_EARN_REWARD_BOOSTER_RCVD: () => 'Received ELITE Earn reward booster',
  NA_YIELD_VAULT_REWARD_BOOSTER_RCVD: () => 'Received ELITE YieldVault reward booster',
  CAKE_ELITE_YEARLY: () => 'ELITE yearly',
  CAKE_ELITE_MONTHLY: () => 'ELITE monthly',
  NA_DCA_DEPOSIT_RCVD: () => 'Deposit recurring buy',
  DCA_PROMO_BONUS_RCVD: () => 'Promotion bonus',
  SH_DCA_PROMO_BONUS_RCVD: () => 'Promotion bonus',
  DEPOSIT_PERCENTAGE_FEE: () => 'Deposit fee paid',
  NA_DCA_REFERRAL_REWARD_RCVD: () => 'DCA referral rewards',
  NA_COIN_PURCHASE_DEPOSIT_RCVD: () => 'One-time buy',
  NA_ASSET_SWAP_SENT: () => 'Sell token',
  NA_ASSET_SWAP_RCVD: () => 'Buy token',
  NA_ASSET_SWAP_FEE_SENT: () => 'Swap fee',
  NA_BUNDLE_ENTER_SENT: () => 'Bundle buy',
  NA_BUNDLE_EXIT_RCVD: () => 'Bundle sell',
  NA_BUNDLE_ENTER_FEE_SENT: () => 'Bundle fee',
  NA_BUNDLE_EXIT_FEE_SENT: () => 'Bundle fee',
  NA_DEPOSIT_PERCENTAGE_FEE_SENT: () => 'Deposit fee',
  NA_DUST_CONVERSION_RCVD: () => 'Dust converted',
  NA_DUST_CONVERSION_SENT: () => 'Dust convert',
  NA_BUNDLE_COMMISSIONS_RCVD: () => 'Bundle Commissions',
  YP_YIELD_PRODUCT_RCVD: () => 'Lending shares received',
  NA_YIELD_PRODUCT_RCVD: () => 'Received token from Lending shares',
  YP_YIELD_PRODUCT_SENT: () => 'Lending shares sold',
  NA_YIELD_PRODUCT_SENT: () => 'Sell token for Lending shares',
  NA_BUNDLE_REFERRAL_REWARD_RCVD: () => 'Custom bundle sign up bonues',
  NA_BUNDLE_NEW_USER_REWARD_RCVD: () => 'Custom bundle sign up bonues',
};

export default function getCsvExportDescription(txType: TxOperation | string, tx = {}) {
  const readableBuilder = TxReadableMap[txType];
  if (!readableBuilder) return 'Unknown';
  return readableBuilder(tx);
}
