import { Coin, UserAccessRights, WalletUserPortfolioResponse } from '@cakedefi/cake-sdk/schema';
import BigNumber from 'bignumber.js';
import { CoinMap } from 'types/coin/coin-map';
import { WalletByCoin } from 'types/coin/wallet-by-coin';
import convertUSDToPreferredCurrency from 'utils/convertUSDToPreferredCurrency';
import { getCoinCategoryName } from 'wallet/utils/getCoinCategoryName';
import { getCoinDisplayName } from 'wallet/utils/getCoinName';

const getIsCoinRestrictedForDMCWithdrawal = (coin: Coin, userAccessRights: UserAccessRights) => coin.networks.length === 1
  && coin.networks[0].id === 'DEFI_METACHAIN' && !userAccessRights.withdrawToMetaChain.access;

export const getCoinAPY = (wallet, lapisAPYs) => {
  if (wallet.stakeable) {
    return (wallet.returnPerAnnum * 100).toFixed(1);
  }
  const coinWithAPY = lapisAPYs?.find((coin) => coin.id === wallet.id);
  if (coinWithAPY) {
    return (coinWithAPY.returnPerAnnum * 100).toFixed(1);
  }
  return '';
};

export const extendWallet = (
  wallet: WalletByCoin,
  coins: CoinMap,
  preferredCurrency: string,
  currencyRates,
  lapisAPYs,
  userAccessRights: UserAccessRights,
  walletUserPortfolio?: WalletUserPortfolioResponse,
): WalletByCoin => {
  let totalBalanceInUSD = new BigNumber(0);
  const coin = coins[wallet.id] || {} as Coin;

  if (walletUserPortfolio?.portfolioAssets && walletUserPortfolio.portfolioAssets[coin.id]) {
    totalBalanceInUSD = new BigNumber(walletUserPortfolio.portfolioAssets[coin.id].totalInUsd);
  }

  const isCoinRestrictedForDMCWithdrawal = getIsCoinRestrictedForDMCWithdrawal(coin, userAccessRights);

  return {
    ...wallet,
    value: convertUSDToPreferredCurrency(totalBalanceInUSD, coins.BTC?.priceUSD, preferredCurrency, currencyRates),
    displayName: getCoinDisplayName(coin),
    apy: getCoinAPY(wallet, lapisAPYs),
    decimals: coin.decimals,
    canStake: coin.sharePurchasable || false,
    canUnstake: coin.unstakingDisabled || false,
    canDeposit: coin.coinDepositable || false,
    canWithdraw: (!isCoinRestrictedForDMCWithdrawal && coin.coinWithdrawable) || false,
    coinPurchasableWithFiat: coin.coinPurchasableWithFiat || false,
    shareSwappable: coin.shareSwappable || false,
    type: getCoinCategoryName(coin),
    ...coin,
  };
};
