/* eslint-disable no-restricted-globals */
import { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { storage } from '../../storage/sessionStorage';

/**
 * when an entry is added in DeepLinking.ts for mobile, or in webapp apple-app-site-association,
 * please do remember to add it here.
 */

const DEEPLINK_FORCE_CONFIG = [
  'discover/xboarding/discover',
  'discover/xboarding/landing',
  'wallets',
  'bake',
  'portfolio',
  'account',
  'me',
  'me/notifications',
  'me/profile',
  'referral',
  'me/allocation-preferences',
  'staking',
  'liquidity-mining',
  'yield-vault',
  'me/verify-account',
  'me/withdrawal-address',
  'me/security',
  'dca/success',
  'dca/failed',
  'dca/manage',
  'dca/buy',
  'transactions',
  // wildcard routes at the bottom
  'buy/*',
  'product/*',
  'select-asset-screen/*',
  'recurring-buy/*',
  'discover/*',
  'lending',
  'buy/nuvei/success',
  'buy/nuvei/failed',
];

export default class BasePage extends Component {
  state = {

  }

  cakepool = window.cakepool;

  componentDidMount() {
    const query = this.getQuery();

    const utmSource = query.get('utm_source');
    if (utmSource) {
      ['pid', 'utm_source', 'utm_medium', 'utm_campaign'].forEach(utm => {
        storage.setItem(utm, query.get(utm));
      });
    }

    const isProdEnv = process.env.NODE_ENV === 'production';
    const isNotOwnReferrer = isProdEnv ? !document?.referrer.startsWith('https://app.bake.io') : !document?.referrer.startsWith('https://app.muffindefi.com');

    // NOTE: this deeplink is only for mobile
    if (isMobile
      && isNotOwnReferrer
      && DEEPLINK_FORCE_CONFIG.some(link => (
        link.endsWith('*')
          ? location.pathname.startsWith(`/${link.replace('*', '')}`)
          : (link === `/${location.pathname}` || location.pathname.endsWith(link))))
    ) {
      const deepLinkUrl = isProdEnv ? `bake:/${location.pathname}` : `muffin:/${location.pathname}`;
      window.location.replace(deepLinkUrl);
      // ^ the above implementation is correct - location.pathname starts with a slash
    }
  }

  navigate(target, state = {}) {
    this.props.history.push(target);
    this.cakepool.navigationState = state;
  }

  getQuery() {
    const { location } = this.props;
    return new URLSearchParams(location ? location.search : undefined);
  }
}
