import BigNumber from 'bignumber.js';
import { MinimumAmountInUsd } from '../../schema';
import { StakingUnstake } from '../../schema/stake';
import { CakeApiBase } from '../CakeApiBase';

export class StakingApi extends CakeApiBase {
  async stake(coinId: string, stakedAmount: string | BigNumber, immediatelyFreezeMonth?: number, autoRenew?: boolean) {
    return this.requestManager.post<boolean>('staking/stake', {
      coinId,
      stakedAmount,
      immediatelyFreezeMonth,
      autoRenew,
    });
  }

  async unstake(coinId: string, stakedAmount: string) {
    return this.requestManager.post<StakingUnstake>('staking/unstake', {
      coinId,
      stakedAmount,
    });
  }

  async getMinimumInput() {
    return this.requestManager.get<MinimumAmountInUsd>('/staking/minimum');
  }
}
