import { getSVGRequiredPath } from './getSVGRequiredPath';

export const SVGCoins = [
  'bch',
  'btc',
  'chaincoin',
  'dash',
  'dfi',
  'doge',
  'eth',
  'cseth',
  'ltc',
  'nrg',
  'pivx',
  'mock',
  'sins',
  'syscoin',
  'usdc',
  'euroc',
  'usdt',
  'xzc',
  'xmas-btc',
  'matic',
  'sol',
  'sui',
  'dot',
  'ada',
  'near',
  'van',
  'strk',
  'op',
  'arb',
  'aave',
  'algo',
  'avax',
  'axs',
  'tia',
  'link',
  'atom',
  'mana',
  'eos',
  'fet',
  'imx',
  'inj',
  'ldo',
  'mkr',
  'egld',
  'nmr',
  'ocean',
  'paxg',
  'qnt',
  'rndr',
  'stx',
  'xlm',
  'xtz',
  'grt',
  'sand',
  'uni',
  'xrp',
  'hbar',
  'ftm',
  'snx',
  'super',
  'gala',
  'enj',
  'ygg',
  'shib',
  'pepe',
  'floki',
  'bonk',
  'jav',
  'aero',
  'aioz',
  'akt',
  'api3',
  'band',
  'btt',
  'cake',
  'cfg',
  'coq',
  'dia',
  'dodo',
  'dog',
  'dydx',
  'ena',
  'gfi',
  'hnt',
  'joe',
  'jup',
  'mpl',
  'ondo',
  'orai',
  'osmo',
  'pendle',
  'peng',
  'ponke',
  'popcat',
  'pro',
  'pyth',
  'ray',
  'sei',
  'tao',
  'trac',
  'trb',
  'tru',
  'uma',
  'w',
  'wif',
  'storj',
  '1inch',
  'ape',
  'apt',
  'ar',
  'arkm',
  'axl',
  'brett',
  'comp',
  'crv',
  'cvx',
  'degen',
  'flow',
  'flux',
  'glm',
  'gmt',
  'icp',
  'ilv',
  'jasmy',
  'kas',
  'lrc',
  'magic',
  'ntrn',
  'om',
  'prime',
  'ron',
  'rpl',
  'ton',
  'woo',
  'audio',
  'bat',
  'blur',
  'btg',
  'celo',
  'chz',
  'core',
  'cspr',
  'elf',
  'ens',
  'gno',
  'not',
  'skl',
  'xaut',
  'zeta',
  'zil',
  'trx',
  'tbill',
  'atlas',
  'myth',
  'bnx',
  'lobo',
  'uncommongoods',
  'satoshi',
  'decentralized',
  'rsic',
  'cwif',
  'turbo',
  'mog',
  'benji',
  'mew',
  'fil',
  'rune',
  'theta',
  'zk',
  'velo',
  'lpt',
].reduce((acc, coin) => {
  acc[coin] = getSVGRequiredPath(`coins/icon-coin-${coin}`);
  return acc;
}, {});
