import React from 'react';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ThunkApiConfig } from 'app/rootReducer';
import { Trans } from 'react-i18next';
import sdk from 'sdk';
import { reloadUser } from 'user/userSlice';

export interface IVoucher {
  code: string,
  currency: string,
  redeemed: boolean,
  redeemedOn: string,
  value: string,
  voucherSuccessRedeemed: boolean,
  voucherPromotion: {
    createdAt: string,
    currency: string,
    expiryDate: string,
    id: string,
    name: string,
    updatedAt: string,
    value: string,
  }
}

const initialState = {
  isLoading: false,
  userHasRestrictionByCountry: undefined,
  mainMessage: 'Synchronising with Razer...',
  errorMessage: 'This campaign is not available in your country.',
  voucherSuccessRedeemed: false,
};

export const redeemCode = createAsyncThunk<any, { code: string, excludeRazer?: boolean }, ThunkApiConfig>(
  '/voucher/redeem',
  async ({ code, excludeRazer }, thunkAPI) => {
    const result = await sdk.VoucherInfoApi.redeem(code, excludeRazer);
    thunkAPI.dispatch(reloadUser());
    return result;
  },
);

const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    clearCampaignsSlice: () => ({ ...initialState }),
    setMainMessage: (state, action) => {
      state.mainMessage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(redeemCode.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(redeemCode.fulfilled, (state, action) => {
      state.isLoading = false;
      state.voucherSuccessRedeemed = true;
      window.cakepool.showAlert('success', <Trans>Voucher successfully applied</Trans>);
    });
    builder.addCase(redeemCode.rejected, (state, action) => {
      state.isLoading = false;
      window.cakepool.showAlert('error', <Trans>{action.error?.message}</Trans>);
    });
  },
});

export const { clearCampaignsSlice, setMainMessage } = campaignsSlice.actions;

export default campaignsSlice.reducer;
