import { AccessRightPermission, AccessRight, UserAccessRights } from '@cakedefi/cake-sdk';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../app/rootReducer';
import { DEFAULT_CURRENCY } from './LanguageRegionSettings/languageRegionSettingsSelector';

export const LENDING_CUT_OFF_DATE_US = new Date('2021-11-26'); // UTC

export const getUserState = (state: RootState) => state.user;
export const userDetailSelector = createSelector(getUserState, user => user.details);
export const userAccessRightsSelector = createSelector(getUserState, user => user.accessRights ?? {} as UserAccessRights);
export const userAccessRightSelector = (permission: AccessRightPermission) => createSelector(
  userAccessRightsSelector, (accessRights: UserAccessRights): AccessRight => accessRights[permission] ?? {} as AccessRight,
);
export const userHasAccessRightSelector = (permission: AccessRightPermission, allowNonLoggedIn?: boolean) => createSelector(
  userAccessRightSelector(permission), isLoggedInSelector, (accessRight: AccessRight, isLoggedIn): boolean => {
    if (allowNonLoggedIn && !isLoggedIn) {
      return true;
    }
    return accessRight.access;
  },
);
export const userHasAtLeastOneAccessRightSelector = (
  permissions: AccessRightPermission[],
  allowNonLoggedIn?: boolean,
) => createSelector(
  userAccessRightsSelector,
  isLoggedInSelector,
  (accessRights: UserAccessRights, isLoggedIn): boolean => {
    if (allowNonLoggedIn && !isLoggedIn) {
      return true;
    }
    return permissions.some(
      (permission) => accessRights[permission]?.access ?? false,
    );
  },
);

export const isLoggedInSelector = createSelector(getUserState, user => !!user.details);
export const userPreferredCurrencySelector = createSelector(userDetailSelector, user => user?.preference?.preferredCurrency ?? DEFAULT_CURRENCY);
export const isBetaUserSelector = createSelector(
  userDetailSelector,
  (userDetail) => userDetail?.isBetaUser,
);

export const isUSUserSelector = createSelector(
  userDetailSelector,
  (userDetail) => userDetail?.country === 'US',
);

function isBeforeLendingCutOffDate() {
  return new Date() < LENDING_CUT_OFF_DATE_US;
}

export const canUserAccessToLendingSelector = createSelector(
  userDetailSelector,
  userAccessRightsSelector,
  (user, accessRights) => {
    if (!user) {
      return true;
    }
    const { lending } = accessRights;
    if (lending?.access) {
      return true;
    }

    const isUSUser = user.country === 'US';
    if (!isUSUser) {
      return false;
    }

    return isBeforeLendingCutOffDate();
  },
);

export const isShowLendingBannerForUSSelector = createSelector(
  userDetailSelector, userAccessRightsSelector, (user, accessRights) => {
    if (!user || !accessRights) {
      return false;
    }
    const { lending } = accessRights;
    return (
      !lending.access && user.country === 'US' && isBeforeLendingCutOffDate()
    );
  },
);

export const isInfluencerSelector = createSelector(
  userDetailSelector,
  (userDetail) => (
    !!userDetail?.customMinimumDepositForWelcomeBonus
    || !!userDetail?.customDepositBonusUSDReferral
    || !!userDetail?.customDepositBonusUSDReferrer
  ),
);
