import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { ModalHeader, ModalContent, ModalFooter } from 'modals/components';
import { MdCheck, MdClear } from 'react-icons/md';
import { goToModal, reloadLearnEarnModal, resetQuizState, updateUserSavedProgress, fetchUserQuizzesInfo, saveCurrentProgressForModal } from 'learn-earn/learnEarnSlice';
import modalStyles from 'common/styles/Modal.styles';
import Button from 'common/components/Button/Button';
import { useCakeSelector } from 'app/rootReducer';
import Variables, { getMinWidthSm } from 'Variables.styles';
import ModalFooterButton from 'modals/components/ModalFooterButton';
import { getIsStackEligibleToEarn, getCurrentResultData, getLearnEarnState, getDynamicPercentageRates } from 'learn-earn/learnEarnSelector';
import sdk from 'sdk';
import fetchWithSpinner from 'utils/fetchWithSpinner';
import { storage } from 'storage/sessionStorage';
import { getRewardLabel } from 'learn-earn/learnEarnUtils';
import LessonModalQuizOption from './LessonModalQuizOption';
import { ModalType } from './LessonModal';

const useStyles = createUseStyles({
  ...modalStyles,
  explanationContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 15fr',
    margin: `${Variables.medium} 0`,
    padding: Variables.medium,
  },
  alignRight: {
    justifyContent: 'flex-end',
  },
  footer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  hidden: {
    display: 'none',
  },
  contentHeightLarge: {
    minHeight: '200px',
  },
  contentHeightSmall: {
    minHeight: '200px',
  },
  footerRight: {
    padding: `${Variables.small} ${Variables.large}`,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
  },
  footerLeft: {
    padding: `${Variables.small} ${Variables.large}`,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  marginTop: {
    marginTop: '30px',
  },
  bottomMargin: {
    marginBottom: '15px',
  },
  footerTwoButtonsNoBorder: {
    padding: `${Variables.small} ${Variables.large}`,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  guestCongrats: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  secondaryTheme: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    border: Variables.primaryBorder,
  },
  congratsText: {
    color: Variables.verificationGreen,
    fontWeight: 'bold',
  },
  [getMinWidthSm]: {
    hidden: {
      visibility: 'hidden',
    },
    contentHeightLarge: {
      minHeight: '540px',
    },
    contentHeightSmall: {
      minHeight: '390px',
    },
  },
});

const SUCCESS_MESSAGE = 'Congratulations! You have answered correctly';
const PROGRESS_SAVED_MESSAGE = 'Progress saved';

interface LessonModalResultProps {
  closeModal: () => void;
  history: any;
  headerTitle: string;
}

const LessonModalResult = ({ closeModal, history, headerTitle }: LessonModalResultProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { details: userDetails } = useCakeSelector(state => state.user);
  const { userQuizInput } = useCakeSelector(getLearnEarnState);
  const {
    quizQuestion,
    selectedQuizOptionInfo, // defaults to correct info if userQuizInput is undefined
    currentLessonNumber,
    quizId,
    coinId,
    rewardType,
    quizQuestionId,
    showPopupPayoutMessage,
    nextLessonNumber,
    explanation,
    isQuizCorrectAnswer, // defaults to true if userQuizInput is undefined
    isLastQuizAndResult,
    rewardInUsd = 0,
  } = useCakeSelector(getCurrentResultData);
  const isStackEligibleToEarn = useCakeSelector(getIsStackEligibleToEarn(quizId));
  const dynamicPercentageRates = useCakeSelector(getDynamicPercentageRates);
  const { t } = useTranslation();

  const rewardLabel = getRewardLabel(coinId, rewardType);

  useEffect(() => {
    if (userDetails && userQuizInput) {
      dispatch(saveCurrentProgressForModal({ modal: 'RESULT', quizAnswer: userQuizInput }));
    } else if (userDetails) {
      dispatch(saveCurrentProgressForModal({ modal: 'RESULT' }));
    }
  }, [dispatch, userDetails, userQuizInput]);

  const onClickTryAgain = () => {
    dispatch(resetQuizState());
    dispatch(goToModal(ModalType.QUIZ_MODAL));
  };

  const onClickBack = () => {
    dispatch(resetQuizState());
    dispatch(goToModal(ModalType.LESSON_MODAL));
  };

  const updateUserQuiz = async () => {
    let separateQuizProgressId: string;
    if (isLastQuizAndResult) {
      separateQuizProgressId = '';
    }

    let successMessage = PROGRESS_SAVED_MESSAGE;
    if (showPopupPayoutMessage) {
      successMessage = SUCCESS_MESSAGE;
    }

    await fetchWithSpinner(() => sdk.LearnEarnApi.updateUserQuizRecord({
      quizId,
      quizQuestionId,
      separateQuizProgressId,
    }), {
      successMessage,
    });
    dispatch(fetchUserQuizzesInfo());
  };

  useEffect(() => {
    if (isQuizCorrectAnswer) {
      if (userDetails) {
        updateUserQuiz();
      } else {
        storage.setItem('learnAndEarnCodeApplied', `${quizId}_${quizQuestionId}`);
      }
    }
  }, []); // eslint-disable-line

  const onGoNextLesson = () => {
    dispatch(resetQuizState());
    if (userDetails) {
      dispatch(updateUserSavedProgress({ lessonIndex: nextLessonNumber, partIndex: 1, modal: 'LESSON' }));
    }
    dispatch(reloadLearnEarnModal({ isloadFromLocalStorage: true }));
  };

  const onClaimReward = () => {
    history.push('/register');
    closeModal();
  };

  const onGoComplete = () => {
    dispatch(goToModal(ModalType.COMPLETED_MODAL));
  };

  const nonLoggedInCongratsSection = <div>
    <div className={`${classes.contentMini} ${classes.guestCongrats}`}>
      <h2><Trans>Congrats!</Trans></h2>
      <p className={classes.congratsText}>
        <Trans>You've earned ${{ rewardInUsd }} in {{ rewardLabel }} by completing Lesson {{ currentLessonNumber }}. Claim your earnings now and continue earning, by signing up and verifying your account.</Trans>
      </p>
    </div>
    <div className={`${classes.centerContent} ${classes.bottomMargin}`}>
      <Button primary onClick={onClaimReward}>
        <Trans>Claim my earnings</Trans>
      </Button>
    </div>
  </div>;

  const loggedInCongratsSection = <div>
    {
      isStackEligibleToEarn && <div className={`${classes.centerContent} ${classes.marginTop}`}>
        <h2><Trans>Congrats!</Trans></h2>
        <p className={classes.congratsText}>
          {
            userDetails && userDetails.kycFullStatus === 'APPROVED'
              ? <Trans>You've earned ${{ rewardInUsd }} in {{ rewardLabel }} by completing Lesson {{ currentLessonNumber }}.</Trans>
              : <Trans>You've earned ${{ rewardInUsd }} in {{ rewardLabel }} by completing Lesson {{ currentLessonNumber }}. Claim it later by verifying your account.</Trans>
          }
        </p>
      </div>
    }
    <div className={classes.footerTwoButtonsNoBorder}>
      <ModalFooterButton primary className={classes.secondaryTheme} buttonText="Back" onClick={onClickBack} arrow="left"/>
      { nextLessonNumber && <ModalFooterButton primary className={classes.secondaryTheme} onClick={onGoNextLesson} buttonText={t('Go to lesson {{nextLessonNumber}}', { nextLessonNumber })}/> }
      { isLastQuizAndResult && <ModalFooterButton primary onClick={onGoComplete} buttonText={'Complete'}/> }
    </div>
  </div>;

  const correctAnswerComponent = userDetails ? loggedInCongratsSection : nonLoggedInCongratsSection;

  const wrongAnswerComponent = <div className={classes.footerLeft}>
    <ModalFooterButton primary className={classes.secondaryTheme} buttonText="Try again" onClick={onClickTryAgain} arrow="left"/>
  </div>;

  return (
    <>
      <ModalHeader title={headerTitle} onClose={closeModal}/>
      <ModalContent>
        <div className={`${classes.contentMini} ${isQuizCorrectAnswer && isStackEligibleToEarn ? classes.contentHeightSmall : classes.contentHeightLarge}`}>
          <p><Trans i18nKey={quizQuestion}>{quizQuestion}</Trans></p>
          <LessonModalQuizOption
            key={userQuizInput}
            quizOptionData={selectedQuizOptionInfo}
            isQuizCorrectAnswer={isQuizCorrectAnswer}
            />
          <div className={classes.explanationContainer} >
            { isQuizCorrectAnswer ? <MdCheck size="24px" color={Variables.verificationGreen}/> : <MdClear size="24px" color={Variables.red}/>}
            <div>
              {t(explanation, dynamicPercentageRates)}
            </div>
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        { isQuizCorrectAnswer ? correctAnswerComponent : wrongAnswerComponent }
      </ModalFooter>
    </>
  );
};

export default LessonModalResult;
