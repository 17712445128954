import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { MdError } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { userPreferredCurrencySelector } from 'user/userSelector';
import { trackMixPanel } from 'utils/trackMixpanel';
import Button from '../../../common/components/Button/Button';
import { FiatBuyInitialStepSelectorModal, SelectedProviderSelectorModel } from '../../../types/onramp/state';
import FiatBuyInputGroups from './FiatBuyInputGroups';
import { CONFIRMATION, SWAPPABLE_CONFIRMATION, useFiatBuyStyles, useModalStyles } from './FiatBuyModal';
import { initialFetch } from './fiatBuyModalAction';
import { fiatBuyInitialStepSelector, providerFiatsSelector, selectedProviderSelector } from './fiatBuyModalSelector';
import { isDisableContinueButtonSelector } from './store/isDisableContinueButtonSelector';
import FiatOnrampProvider from './FiatOnrampProvider';
import PaymentMethods from './PaymentMethods';
import PriceBreakdown from './PriceBreakdown';
import AddressCreationAcknowledge from './components/AddressCreationAcknowledge';
import { isShowETHFeeSelector } from './store/isShowETHFeeSelector';
import RecurringBuyBannerForBuyCrypto from '../RecurringBuyBannerForBuyCrypto/RecurringBuyBannerForBuyCrypto';

type InitialPageProps = {
  goTo: Dispatch<SetStateAction<string>>;
  onClose: () => void;
  selectedCoin: string;
}

const InitialPage = ({ goTo, onClose, selectedCoin }: InitialPageProps) => {
  const [pricePromise, setPricePromise] = useState(null);
  const [hasAcknowledgeCreationFee, setHasAcknowledgeCreationFee] = useState(false);
  const modalClasses = useModalStyles();
  const fiatBuyClasses = useFiatBuyStyles();
  const dispatch = useDispatch();
  const preferredCurrency = useSelector(userPreferredCurrencySelector);
  const isShowETHFee = useSelector(isShowETHFeeSelector);
  const shouldSupportTransak = selectedCoin !== 'EUROC';
  const {
    inputs,
    type,
    fiats,
    error,
    fiatSwappable: {
      coin: fiatSwappableCoinId,
    },
    isFiatsLoading,
  }: FiatBuyInitialStepSelectorModal = useSelector(fiatBuyInitialStepSelector);
  const {
    provider: { name: providerName },
  }: SelectedProviderSelectorModel = useSelector(selectedProviderSelector);
  const {
    hasLoadedBanxaFiats,
    hasLoadedTransakFiats,
  } = useSelector(providerFiatsSelector);
  const isDFIfiatSwappableCoinId = fiatSwappableCoinId === 'DFI';

  const cancelPromise = () => {
    if (pricePromise?.abort) {
      pricePromise.abort();
      setPricePromise(null);
    }
  };

  const cancelRequestBeforeFetching = (promise) => {
    cancelPromise();
    if (promise) {
      setPricePromise(promise);
    }
  };

  useEffect(() => {
    trackMixPanel({
      type: 'track',
      event: 'Buy Page Viewed',
      properties: {
        buy_page_viewed: true,
        coin_type: selectedCoin,
      },
    });
  }, [selectedCoin]);

  useEffect(() => {
    initialFetch(dispatch, type, hasLoadedBanxaFiats, hasLoadedTransakFiats, selectedCoin, preferredCurrency, inputs, fiatSwappableCoinId, cancelRequestBeforeFetching, shouldSupportTransak);
    // Disabling exhaustive-deps because we just want to re-render only if the selectedCoin prop changed.
    // eslint-disable-next-line
  }, [selectedCoin, type]);

  const isDisabledButton = useSelector(isDisableContinueButtonSelector(selectedCoin));
  const isDisabledButtonWithETHFee = isShowETHFee
    ? !hasAcknowledgeCreationFee || isDisabledButton
    : isDisabledButton;

  const goNext = () => {
    const nextStep = fiatSwappableCoinId ? SWAPPABLE_CONFIRMATION : CONFIRMATION;
    goTo(nextStep);
  };

  const headerTemplate = (
    <>
      <header className={modalClasses.header}>
        <h2>
          <Trans>Buy cryptocurrency</Trans>
        </h2>
        <button className={fiatBuyClasses.inlineLinkButton} onClick={onClose}>
          <Trans>Cancel</Trans>
        </button>
      </header>
      <div className={modalClasses.contentContainer}>
        <FiatOnrampProvider selectedCoin={selectedCoin}/>
      </div>
    </>
  );
  const hasNoFiats = !isFiatsLoading && fiats && !fiats.length;
  if (hasNoFiats) {
    const message = `${providerName} doesn't support your country.`;
    return (
      <>
        {headerTemplate}
        <p className={fiatBuyClasses.warningMessage}>
          <MdError className={fiatBuyClasses.errorIcon} />
          <Trans>{message}</Trans>
        </p>
      </>
    );
  }

  return (
    <>
      {headerTemplate}
      <div
        className={`${modalClasses.contentFluid} ${fiatBuyClasses.priceAndPaymentContainer}`}
      >
        <FiatBuyInputGroups selectedCoin={selectedCoin} cancelRequestBeforeFetching={cancelRequestBeforeFetching} />
        {isDFIfiatSwappableCoinId ? <RecurringBuyBannerForBuyCrypto /> : null}
        <PaymentMethods cancelRequestBeforeFetching={cancelRequestBeforeFetching}></PaymentMethods>
      </div>
      {error ? (
        <p className={`${modalClasses.centerContent} ${modalClasses.contentContainer}`}>
          <MdError className={fiatBuyClasses.errorIcon} /><Trans>{error}</Trans>
        </p>
      ) : null}
      <PriceBreakdown></PriceBreakdown>
      {isShowETHFee && (
        <AddressCreationAcknowledge
          hasAcknowledge={hasAcknowledgeCreationFee}
          setHasAcknowledge={setHasAcknowledgeCreationFee}
        />
      )}
      <footer className={modalClasses.footer}>
        <Button
          primary
          disabled={isDisabledButtonWithETHFee}
          onClick={goNext}
        >
          <Trans>Continue</Trans>
        </Button>
      </footer>
    </>
  );
};

export default InitialPage;
