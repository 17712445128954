/* eslint-disable camelcase */
import React from 'react';
import { Trans, Translation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import withStyles from 'react-jss';
import Variables from 'Variables.styles';
import { RootState } from 'app/rootReducer';
import EmailPasswordInput from 'views/components/Register/EmailPasswordInput';
import { trackSnapchat } from 'views/containers/MainLayout/Snapchat';
import { trackReddit } from 'utils/trackReddit';
import { setVoucherCodeInLocal } from 'voucher/voucherSlice';
import { getSessionUtmInfos } from 'utils/getSessionUtmInfos';
import { trackAdroll } from 'utils/trackAdroll';
import { trackMixPanel } from 'utils/trackMixpanel';
import TermsAndPrivacy from 'auth/components/TermsAndPrivacy';
import RegisterMethods from 'auth/components/RegisterMethods';
import ReferralUI from '../../auth/components/ReferralUI';
import { getQueryParam } from '../../utils/getQueryParam';
import {
  isValidEmail,
  isEmpty,
  isValidPassword,
} from '../../utils';
import sdk from '../../sdk';
import { InvisibleRecaptcha } from '.';
import { userTokenInfoStorage } from '../../storage';
import BasePage from '../BasePage';
import { getPreferredLanguage } from '../../user/LanguageRegionSettings/languageRegionSettingsUtils';
import { ModalContext } from '../../modals/ModalContext';
import Button from '../../common/components/Button/Button';
import { storage } from '../../storage/sessionStorage';
import { reloadUser } from '../../user/userSlice';
import {
  setRegisterStep,
  setRevealInfoToReferrer,
  setAcceptedTermsAndConditions,
  setRegisterReCaptchaToken,
  setUserInputCode,
  setIsRefCodeNoLongerValid,
} from '../Pages/Authentication/authSlice';
import PromoAndReferralInput from './Register/PromoAndReferralInput';
import PromoCodeInfo from '../../common/components/PromoCodeInfo/PromoCodeInfo';
import { getIsPromotionPeriodSelector } from '../../referral/referralPromoSelector';
import gtmTrack, { iEventNames, iEventTypes } from '../../common/hooks/gtmTrack/gtmTrack';

const mapDispatch = ({
  reloadUser,
  setRegisterStep,
  setRevealInfoToReferrer,
  setAcceptedTermsAndConditions,
  setRegisterReCaptchaToken,
  setUserInputCode,
  setIsRefCodeNoLongerValid,
  setVoucherCodeInLocal,
});

export const isReferralIdInFormat = (userInputReferralCode: string) => new RegExp(/^[0-9]{6,7}$/).test(userInputReferralCode);

export function trackSignupEvents(email: string) {
  trackSnapchat('SIGN_UP', {
    user_email: email,
    description: email,
    success: 1,
  });
  trackReddit('SignUp');
  trackAdroll();
  trackMixPanel({
    type: 'track',
    event: 'Sign Up Completed',
    properties: {
      sign_up_completed: true,
      sign_up_device: 'webapp',
    },
  });
}

const styles = {
  redirectToLoginContainer: {
    marginTop: Variables.large,
    marginBottom: Variables.large,
  },
  linkText: {
    marginLeft: Variables.small,
  },
};

const mapState = (state: RootState) => ({
  languageRegionSettings: state.languageRegionSettings,
  referralCode: state.referralCode.value,
  promoCode: state.referralCode.promoCode,
  email: state.auth.email,
  password: state.auth.password,
  userInputCode: state.auth.userInputCode,
  registerStep: state.auth.registerStep,
  isReferralCodeInput: state.auth.isReferralCodeInput,
  revealInfoToReferrer: state.auth.revealInfoToReferrer,
  acceptedTermsAndConditions: state.auth.acceptedTermsAndConditions,
  acceptedDataConsent: state.auth.acceptedDataConsent,
  acceptedMarketingConsent: state.auth.acceptedMarketingConsent,
  isReferralFieldVisible: state.auth.isReferralFieldVisible,
  registerReCaptchaToken: state.auth.registerReCaptchaToken,
  isRefCodeNoLongerValid: state.auth.isRefCodeNoLongerValid,
  country: state.auth.country,
  voucher: state.voucher,
  age: state.auth.age,
  isPromotion: getIsPromotionPeriodSelector(state),
});

class RegisterInputs extends BasePage {
  recaptchaRef = React.createRef<any>(); // nosemgrep: typescript.react.security.audit.react-no-refs.react-no-refs

  static contextType = ModalContext;

  getSessionReferralCode() {
    if (this.props.isRefCodeNoLongerValid) {
      return '';
    }
    const queryParams = new URLSearchParams(window.location.search);
    return this.props.referralCode || storage.getItem('referralCode') || queryParams.get('ref');
  }

  getPromoCode() {
    return this.props.promoCode || storage.getItem('promoCode') || getQueryParam('promo');
  }

  getAffiliateRefCode() {
    return this.props.affiliateRefCode || storage.getItem('affiliateRefCode') || getQueryParam('pid');
  }

  componentDidMount(): void {
    this.props.setRegisterStep(1);
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.props.registerReCaptchaToken !== prevProps.registerReCaptchaToken) {
      this.register();
    }
  }

  render() {
    const referralCode = this.getSessionReferralCode();
    const promoCode = this.getPromoCode();
    const referralOrPromoCode = referralCode || promoCode;
    const label = promoCode ? 'Promo code' : 'Referral code';
    const referralfield = referralOrPromoCode ? { display: 'none' } : {};
    const referralCodeText = referralOrPromoCode ? {} : { display: 'none' };
    const learnAndEarnCodeApplied = storage.getItem('learnAndEarnCodeApplied');

    const {
      classes,
      registerStep,
      revealInfoToReferrer,
    } = this.props;

    return (
      <Translation>
        {
          t => <form>
            {
              registerStep === 1 && (
                <RegisterMethods />
              )
            }
            {
              registerStep === 2 && (
                <>
                  <EmailPasswordInput
                    onRegister={this.onRegisterSubmit.bind(this)}
                  />
                  <PromoAndReferralInput
                    t={t}
                    referralfield={referralfield}
                    onRegister={this.onRegisterSubmit.bind(this)}
                  />
                </>
              )
            }

            {
              learnAndEarnCodeApplied
              && <div className="margin-top-24 margin-bottom-24 align-center">
                <Trans>You will receive $0.20 once you verify your account.</Trans>
              </div>
            }
            {/* TODO: Validate promo name here */}
            {promoCode && <div className="margin-top-24 margin-bottom-24 align-center" style={referralCodeText}>
              <Trans>{{ label }} has been applied</Trans>:<br></br>
              {referralOrPromoCode}

              {referralOrPromoCode === 'GRAB'
                && <PromoCodeInfo
                  containerStyles={{ marginTop: 24 }}
                  title={'Grab users exclusive:'}
                  subtitle={'Earn an additional 5 USD in DFI when you sign up & complete KYC.'}
                  terms={'*DFI reward will be automatically staked and frozen for 1 month.'}
                />
              }
              {referralOrPromoCode === 'PLENTINA'
                && <PromoCodeInfo
                  containerStyles={{ marginTop: 24 }}
                  title={'Plentina users exclusive:'}
                  subtitle={'Earn an additional US$5 in DFI when you sign up and complete KYC. Earn even more with your first deposit and allocation.'}
                  terms={'*Your DFI reward will be automatically staked & frozen for 1 month.'}
                />
              }
              {referralOrPromoCode === 'HUATAH'
                && <PromoCodeInfo
                  containerStyles={{ marginTop: 24 }}
                  title={'Chinese New Year 2023 exclusive for new users:'}
                  subtitle={'Earn an additional US$18 or US$88 when you allocate at least US$88 or US$288 respectively into Liquidity Mining or Staking, and freeze that allocation for 6 months or more.'}
                  terms={'*Reward is in DFI and will be automatically frozen for 6 months. Reward is applicable on your first Freezer allocation only.'}
                />
              }
              {referralOrPromoCode === 'HUATAH23'
                && <PromoCodeInfo
                  containerStyles={{ marginTop: 24 }}
                  title={'Chinese New Year 2023 exclusive for new users:'}
                  subtitle={'Earn an additional US$18 or US$88 when you allocate at least US$88 or US$288 respectively into Liquidity Mining or Staking, and freeze that allocation for 6 months or more.'}
                  terms={'*Reward is in DFI and will be automatically frozen for 6 months. Reward is applicable on your first Freezer allocation only. Capped at 500 redemptions'}
                />
              }
              {referralOrPromoCode === 'IBAKECRYPTO'
                && <PromoCodeInfo
                  containerStyles={{ marginTop: 24 }}
                  title={'Exclusive for new users:'}
                  subtitle={'Earn an additional US$50 when you allocate at least US$50 respectively into Liquidity Mining or Staking, and freeze that allocation for 6 months or more.'}
                  terms={'*Reward is in DFI and will be automatically frozen for 6 months. Reward is applicable on your first Freezer allocation only. Capped at 1000 redemptions'}
                />
              }
            </div>}

            {!promoCode && <ReferralUI
              showReferralCodeMessage={true}
              referralCodeText={referralCodeText}
              revealInfoToReferrer={revealInfoToReferrer}
              sessionReferralCode={referralCode}
              onReveal={event => this.props.setRevealInfoToReferrer(event)}
            />}
            <InvisibleRecaptcha // nosemgrep: typescript.react.security.audit.react-no-refs.react-no-refs
              ref={this.recaptchaRef}
              onReCaptchaComplete={reCaptchaToken => this.onReCaptchaChange(reCaptchaToken)}
              selectedLanguage={this.props.languageRegionSettings.selectedLanguage}
              level='secure'
            />
            {registerStep === 1
              ? (<div className={classes.redirectToLoginContainer}>
                <Trans>Have an account?</Trans>
                <Link to="/login" className={classes.linkText}>
                  <Trans>Login</Trans>
                </Link>
              </div>)
              : this.renderSubmitButton()}
            <TermsAndPrivacy />
          </form>
        }
      </Translation>
    );
  }

  onRegisterSubmit(): void {
    const canRegister = this.canRegister();
    if (!canRegister) {
      this.cakepool.showAlert('error', <Trans>Please fill out all fields correctly.</Trans>);
      return;
    }
    if (this.isEnabledSubmit()) {
      this.register();
    }
  }

  isEnabledSubmit(): boolean {
    const {
      registerStep,
      acceptedTermsAndConditions,
      acceptedDataConsent,
    } = this.props;

    return registerStep === 2
      && this.canRegister()
      && acceptedTermsAndConditions
      && acceptedDataConsent;
  }

  renderSubmitButton() {
    return (
      <Button
        outline
        className="signup-btn"
        primary
        block
        type="button"
        disabled={!this.isEnabledSubmit()}
        onClick={() => this.onRegisterSubmit()}
        tabIndex={3}>
        <Trans>Sign up</Trans>
      </Button>
    );
  }

  // #region computed
  canRegister() {
    const {
      email,
      password,
      country,
    } = this.props;

    return isValidEmail(email)
      && isValidPassword(password)
      && country;
  }

  setValue(name, value, callback?) {
    this.setState({
      [name]: value,
    }, callback);
  }

  async register() {
    if (this.recaptchaRef?.current) {
      await this.recaptchaRef.current.submit();
    }
    if (this.props.registerReCaptchaToken) {
      return this.registerSubmit();
    }
    return null;
  }

  async registerSubmit() {
    const {
      page,
      languageRegionSettings,
      email,
      password,
      userInputCode,
      revealInfoToReferrer,
      acceptedDataConsent,
      acceptedMarketingConsent,
      registerReCaptchaToken,
      country,
      age,
    } = this.props;

    this.cakepool.showSpinner();
    try {
      const currentLanguage = getPreferredLanguage(languageRegionSettings.selectedLanguage);
      const inputReferralCode = this.getSessionReferralCode();
      const inputPromoCode = this.getPromoCode();
      const affiliateRefCode = this.getAffiliateRefCode();
      const utmInfos = getSessionUtmInfos();

      const getReferralId = () => {
        if (isEmpty(inputReferralCode) && (isEmpty(userInputCode) || !isReferralIdInFormat(userInputCode))) {
          return null;
        }
        return inputReferralCode || userInputCode;
      };

      const getPromoCode = () => {
        if (isEmpty(inputPromoCode) && (isEmpty(userInputCode))) {
          return null;
        }
        return inputPromoCode || userInputCode;
      };

      const referredById = getReferralId();
      const promoCode = referredById ? null : getPromoCode();

      gtmTrack('trackEvent', {
        event: iEventNames.start_sign_up,
        event_type: iEventTypes.custom,
        event_data: {
          referral_code: this.getSessionReferralCode(),
        },
      }); // GTM tracking for sign up button click

      const learnAndEarnCode = storage.getItem('learnAndEarnCodeApplied');
      const { voucherCode } = this.props.voucher;
      userTokenInfoStorage.set('signUpEmail', email);
      await sdk.UserInfoApi.register(
        email,
        password,
        registerReCaptchaToken,
        acceptedDataConsent,
        acceptedMarketingConsent,
        promoCode,
        affiliateRefCode,
        referredById,
        currentLanguage,
        utmInfos.utm_source,
        utmInfos.utm_medium,
        utmInfos.utm_campaign,
        revealInfoToReferrer,
        learnAndEarnCode,
        voucherCode,
        '', // firstName
        '', // lastName
        country,
        age,
      );
      trackSignupEvents(email);
      storage.removeItem('learnAndEarnCodeApplied');
      await this.props.setVoucherCodeInLocal({ code: '' });
      this.resetRecaptcha();
      page.navigate('/email-verification/email');
      this.cakepool.hideSpinner();
    } catch (err) {
      this.cakepool.hideSpinner();
      const { code: errorCode, message: errorMessage, response } = err;

      let template: React.ReactNode = '';

      if (errorCode === 'UnverifiedEmail') {
        page.props.history.push('/email-verification/email/true');
        return;
      } if (response && response.data) {
        const { code: responseCode, message: responseMessage } = response.data;
        if (responseCode === 'UserFound') {
          template = (<div><Trans>{responseMessage}</Trans></div>);
        }
      }

      if (errorCode === 'NoNewReferrals') {
        this.clearUserRefInput();
      }

      if (template === '') {
        template = (<Trans>{errorMessage}</Trans>);
      }

      await this.props.setVoucherCodeInLocal({ code: '' });
      this.cakepool.showAlert('error', template);
      this.props.setRegisterStep(1);
    } finally {
      this.resetRecaptcha();
    }
  }

  clearUserRefInput() {
    this.props.setIsRefCodeNoLongerValid(true);
    this.props.setUserInputCode('');
  }

  resetRecaptcha() {
    this.props.setRegisterReCaptchaToken('');
    if (this.recaptchaRef?.current) {
      this.recaptchaRef.current?.reset();
    }
  }

  onReCaptchaChange(registerReCaptchaToken) {
    this.props.setRegisterReCaptchaToken(registerReCaptchaToken);
  }
}

export default connect(mapState, mapDispatch)(withStyles(styles)(RegisterInputs));
