import { createSelector } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';
import { userPreferredCurrencySelector } from 'user/userSelector';
import { isEmpty } from 'utils';
import { walletUserPortfolioSelector } from 'wallet/walletSelectors';
import { currencyFiatRatesSelector, getPricingForCoin } from '../currency/currencySelector';
import convertUSDToPreferredCurrency from '../utils/convertUSDToPreferredCurrency';

export const tooltipBalanceInPreferredCurrencySelector = () => createSelector(
  currencyFiatRatesSelector,
  getPricingForCoin('BTC'),
  userPreferredCurrencySelector,
  walletUserPortfolioSelector,
  (
    currencyRates,
    usdPricePerBTC,
    preferredCurrency,
    walletUserPortfolio,
  ): {
    stakingBalance: string;
    lmBalance: string;
    yieldVaultBalance: string;
    bundleBalance: string;
  } => {
    const shouldConvertCurrency = preferredCurrency !== 'USD';
    const cannotConvertCurrency = shouldConvertCurrency && isEmpty(currencyRates);
    if (cannotConvertCurrency || !walletUserPortfolio) {
      return null;
    }
    const {
      totalBundleValueInUsd,
      allocatedAssets,
    } = walletUserPortfolio;

    const lmBalanceInUsd = allocatedAssets.liquidityMining.reduce((acc, value) => BigNumber.sum(acc, new BigNumber(value.totalInUsd)), new BigNumber(0));
    const stakingBalanceInUsd = allocatedAssets.staking.reduce((acc, value) => BigNumber.sum(acc, new BigNumber(value.totalInUsd)), new BigNumber(0));
    const yieldVaultBalanceInUsd = allocatedAssets.yieldVault.reduce((acc, value) => BigNumber.sum(acc, new BigNumber(value.totalInUsd)), new BigNumber(0));

    const [
      stakingBalance,
      lmBalance,
      yieldVaultBalance,
      bundleBalance,
    ] = [
      stakingBalanceInUsd,
      lmBalanceInUsd,
      yieldVaultBalanceInUsd,
      totalBundleValueInUsd,
    ].map((item) => convertUSDToPreferredCurrency(
      item,
      usdPricePerBTC,
      preferredCurrency,
      currencyRates,
    ));

    return {
      stakingBalance,
      lmBalance,
      yieldVaultBalance,
      bundleBalance,
    };
  },
);
