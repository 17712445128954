import { CoinPrice } from '@cakedefi/cake-sdk/schema';
import { CoinMap } from 'types/coin/coin-map';
import { convertBTCOrETHToCryptoAmount } from 'utils/convertBTCETHToCryptoAmount';
import { convertCryptoToBTCorETHAmount } from 'utils/convertCryptoToBTCorETHAmount';

export const isStakeWithSharesCoin = inputs => inputs.swappableCoin === inputs.coinToSwap;

export const setCoinToSwapAmount = (state, pricing: CoinPrice[], coins: CoinMap, withoutMarkup: boolean) => {
  const { swappableCoin, swappableCoinAmount, coinToSwap } = state.inputs;
  if (!pricing || !swappableCoinAmount) {
    return;
  }

  if (isStakeWithSharesCoin(state.inputs)) {
    state.inputs.coinToSwapAmount = swappableCoinAmount;
    return;
  }

  state.inputs.coinToSwapAmount = convertCryptoToBTCorETHAmount(swappableCoin, swappableCoinAmount, coinToSwap, coins[coinToSwap]?.decimals, pricing, withoutMarkup);
};

export const setSwappableCoinAmount = (state, pricing: CoinPrice[], withoutMarkup: boolean) => {
  const { swappableCoin, swappableCoinDecimals, coinToSwap, coinToSwapAmount } = state.inputs;
  if (!pricing || !coinToSwapAmount) {
    return;
  }

  if (isStakeWithSharesCoin(state.inputs)) {
    state.inputs.swappableCoinAmount = coinToSwapAmount;
    return;
  }

  state.inputs.swappableCoinAmount = convertBTCOrETHToCryptoAmount(coinToSwap, coinToSwapAmount, swappableCoin, swappableCoinDecimals, pricing, withoutMarkup);
};

export const isCoinSwapBaseCoin = (walletByCoins, coinId) => {
  const coinToSwapCoin = walletByCoins.find(({ id }) => id === coinId);
  return coinToSwapCoin && coinToSwapCoin.coinSwapBaseCoin;
};
