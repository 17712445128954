import React, { Component, useCallback } from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
  withRouter,
  Redirect,
  useHistory,
} from 'react-router-dom';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import './App.scss';
import { createTheme, ThemeProvider } from '@material-ui/core';
import debounce from 'lodash.debounce';
import { AxiosError } from 'axios';
import { initAnalytics, initMixpanel, initDatadog } from 'app-init';
import { setRedeemVoucherCode } from 'user/RedeemVoucher/redeemSlice';
import gtmTrack from 'common/hooks/gtmTrack/gtmTrack';
import { MIXEDPANEL_TRACKED_ROUTES, trackMixPanel } from './utils/trackMixpanel';
import { getQueryParam } from './utils/getQueryParam';
import { userTokenStorage, userTokenInfoStorage } from './storage';
import request from './api/request';
import { MainLayout } from './views/containers';
import { Spinner } from './views/components';
import UserRoutes from './config/UserRoutes';
import config from './config/app';
import { store, persistor } from './app/store';
import { clearUser, reloadUser, setCurrentPath, setPreviousPath } from './user/userSlice';
import { fetchCoinsPricing, fetchFiatRates } from './currency/currencySlice';
import { clearBalances, fetchWalletPage, fetchWalletUserPortfolio, reloadBalances } from './wallet/walletSlice';
import { ModalProvider } from './modals/ModalContext';
import { resetStage } from './user/AccountVerification/accountVerificationSlice';
import sdk from './sdk';
import { handleNetworkError } from './networkError/networkErrorService';
import { setPromoCode, setReferralCode, setAffiliateRefCode } from './referral/referralCodeSlice';
import { updateAuthenticatedLayout } from './balance-bar/BalanceBar';
import PageVisibility from './common/hooks/usePageVisibility/PageVisibility';
import { storage, webLocalStorage } from './storage/sessionStorage';
import { logout } from './app/actions';
import RedirectToExternalUrl from './common/components/Redirect/RedirectToExternalUrl';
import getCakeDeFiWebsiteBaseURL from './utils/getCakeDeFiWebsiteBaseURL';
import { StandaloneModalProvider } from './modals/StandaloneModalContext';

const UserPagesHoc = withRouter(MainLayout);

initDatadog();
initAnalytics();
initMixpanel();

const theme = createTheme({
  typography: {
    fontFamily: 'inherit',
  },
});

class App extends Component {
  cakepool;

  balanceReloadTimer: any;

  state = {
    isUserInfoLoaded: false,
    isLoadingBalances: false,
    isReady: false,
  }

  constructor(props) {
    super(props);
    this.cakepool = window.cakepool;
    const { cakepool } = this;
    cakepool.reloadUserInfo = () => this.reloadUserInfo();
    cakepool.logout = () => this.logout();
    this.initRequestErrorHandlers();

    const logoutDebounce = debounce(() => {
      cakepool.logout();
    }, 0);

    sdk.onError = (err, requestConfig: any) => {
      const error = err.error as unknown as AxiosError;
      if ([401, 403].includes((error as any).status)) {
        logoutDebounce(); // TODO this logic probably doesn't work as expected, need to revisit
      }
      const { isBackgroundRequest } = requestConfig;
      if (error.message === 'Network Error') {
        return handleNetworkError(store.dispatch, isBackgroundRequest);
      }
      return new Promise(r => r());
    };
    request.requestInstance.interceptors.response.use(
      async response => response,
      async (error) => {
        const { isBackgroundRequest } = error.config;
        if (error.message === 'Network Error') {
          return handleNetworkError(store.dispatch, isBackgroundRequest);
        }
        throw error;
      },
    );
  }

  componentDidMount() {
    this.setFunctionToCall();
    this.setReferralOrPromoCode();
    this.setVoucherCode();
    store.dispatch(fetchFiatRates() as any);
    store.dispatch(fetchCoinsPricing(false) as any);
    this.reloadUserInfo();
    this.initBalanceFetchingInterval();
    ReactGA.pageview(window.location.hash);
  }

  // eslint-disable-next-line class-methods-use-this
  setVoucherCode() {
    const voucherCode = getQueryParam('voucher');
    if (voucherCode) {
      store.dispatch(setRedeemVoucherCode(voucherCode));
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getReferralCodeFromURL() {
    try {
      const locationPath = (window.location.search || window.location.hash || '');
      const removedHashPath = locationPath.replace(/[/#]/g, '');
      const searchParams = new URLSearchParams(removedHashPath);
      const referralCode = searchParams.get('ref');
      return referralCode;
    } catch (error) {
      return null;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  setFunctionToCall() {
    const functionName = getQueryParam('call');
    if (functionName === 'razer') {
      storage.setItem('callFn', 'razer');
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async setReferralOrPromoCode() {
    const referralCode = this.getReferralCodeFromURL();
    const promoCode = getQueryParam('promo');
    const affiliateRefCode = getQueryParam('pid');

    if (referralCode) {
      if (storage.getItem('promoCode')) {
        storage.clear();
      }
      store.dispatch(setReferralCode(referralCode));
      storage.setItem('referralCode', referralCode);
      await sdk.PromotionApi.create(referralCode);
    }

    if (promoCode) {
      if (storage.getItem('referralCode')) {
        storage.clear();
      }
      store.dispatch(setPromoCode(promoCode));
      storage.setItem('promoCode', promoCode);
      await sdk.PromotionApi.create(promoCode);
    }

    if (affiliateRefCode) {
      if (storage.getItem('affiliateRefCode')) {
        storage.removeItem('affiliateRefCode');
      }
      store.dispatch(setAffiliateRefCode(affiliateRefCode));
      storage.setItem('affiliateRefCode', affiliateRefCode);
    }
  }

  componentWillUnmount() {
    this.clearBalanceFetchingInterval();
  }

  // eslint-disable-next-line
  async reloadBalances() {
    const userToken = userTokenStorage.get();
    const { details: userDetails } = store.getState().user;
    if (userToken && userDetails) {
      try {
        this.setState({
          isLoadingBalances: true,
        });
        await store.dispatch(reloadBalances(true) as any);
      } finally {
        this.setState({
          isLoadingBalances: false,
        });
      }
    }
  }

  initBalanceFetchingInterval() {
    this.balanceReloadTimer = setInterval(() => {
      this.reloadBalances();
    }, config.POLLING_REFRESH_DELAY as number);
  }

  clearBalanceFetchingInterval() {
    clearInterval(this.balanceReloadTimer);
    this.balanceReloadTimer = null;
  }

  initRequestErrorHandlers() {
    const self = this; // eslint-disable-line
    const requestErrorHandlers = request.errorHandlers;
    requestErrorHandlers.Unauthorized = async () => {
      await self.clearUserToken();
      if (self.cakepool.showAlert) {
        self.cakepool.showAlert('danger', 'Unauthorized');
      }
    };
  }

  async reloadUserInfo() {
    const isLoggedIn = userTokenInfoStorage.get('isLoggedIn') === 'true';

    if (isLoggedIn) {
      try {
        await store.dispatch(reloadUser() as any);

        // load user balances only after user object is ready
        store.dispatch(fetchWalletPage() as any);
        store.dispatch(fetchWalletUserPortfolio() as any);

        // load user balances only after user object is ready

        this.setState({
          isReady: true,
        });
      } catch (err) {
        const { message } = err;
        if (message === 'Unauthorized') {
          userTokenStorage.clear();
          this.setState({
            isReady: true,
          });
        } else {
          console.error(err); // eslint-disable-line
        }
      }
    } else {
      this.setState({
        isReady: true,
      });
    }
    this.setState({
      isUserInfoLoaded: true,
    });
  }

  async logout() {
    trackMixPanel({
      type: 'reset',
    });
    const isLoggedIn = userTokenInfoStorage.get('isLoggedIn');
    try {
      if (isLoggedIn) {
        await sdk.logout({ });
      }
    } catch (error) {
      console.error(error); // eslint-disable-line
    } finally {
      await this.clearUserToken();
      store.dispatch(clearBalances());
      store.dispatch(resetStage());
    }
    await this.clearUserToken();
    await this.reloadUserInfo();
    updateAuthenticatedLayout(false);
    store.dispatch(logout());
  }

  // eslint-disable-next-line class-methods-use-this
  async clearUserToken() {
    userTokenStorage.clear();
    userTokenInfoStorage.clear('isLoggedIn');
    storage.removeItem('homepageKycBannerBannerDismissed');
    webLocalStorage.removeItem('isEnableNotificationsBannerHidden');
    store.dispatch(clearUser());
  }

  onVisibilityChange(isVisible: boolean) {
    if (isVisible) {
      this.clearBalanceFetchingInterval();
      if (!this.state.isLoadingBalances) {
        this.reloadBalances();
      }
      this.initBalanceFetchingInterval();
    } else {
      this.clearBalanceFetchingInterval();
    }
  }

  render() {
    const { isReady } = this.state;
    if (!isReady) {
      return (
        <div className="full-window flex-center">
          <Spinner></Spinner>
        </div>
      );
    }
    const routes = [];
    routes.push(<PublicRoute key="public-routes" path="/" name="User" component={<UserPagesHoc routeConfig={UserRoutes} />} />);

    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <ModalProvider>
            <StandaloneModalProvider>
              <PersistGate loading={null} persistor={persistor}>
                <PageVisibility onChange={this.onVisibilityChange.bind(this)}>
                  <BrowserRouter>
                    <Switch>
                      <Redirect from="/lapis" to="/lending" />
                      <Redirect from="/vip" to="/elite" />
                      <Redirect from="/freezer" to="/staking" />
                      <RedirectToExternalUrl path={['/dca/success', '/dca/failed', '/dca/buy', '/dca/manage']} targetUrl={`${getCakeDeFiWebsiteBaseURL()}/recurring-buy-web`} />
                      {routes}
                    </Switch>
                  </BrowserRouter>
                </PageVisibility>
              </PersistGate>
            </StandaloneModalProvider>
          </ModalProvider>
        </ThemeProvider>
      </Provider>
    );
  }
}

function PublicRoute({
  component,
  ...rest
}) {
  const history = useHistory();
  const trackPageView = useCallback((current, previous) => {
    const { details: userDetails } = store.getState().user;
    if (history.location.pathname === '/') { return; }
    const referralCode = storage.getItem('referralCode');
    gtmTrack('pageView', { userDetails, referralCode });
    if (MIXEDPANEL_TRACKED_ROUTES[current]) {
      trackMixPanel({
        type: 'track',
        event: MIXEDPANEL_TRACKED_ROUTES[current].key,
        properties: MIXEDPANEL_TRACKED_ROUTES[current].value,
      });
    }
  }, []); // eslint-disable-line

  React.useEffect(() => {
    store.dispatch(setCurrentPath(history.location.pathname)); // To set current path for the first time
    trackPageView(history.location.pathname, null); // To track the first pageview upon load
    history.listen((location, action) => {
      const { currentPath } = store.getState().user;
      if (currentPath !== '/' && location.pathname !== currentPath) {
        store.dispatch(setPreviousPath(currentPath));
        store.dispatch(setCurrentPath(location.pathname));
      } else {
        store.dispatch(setCurrentPath(location.pathname));
      }
      trackPageView(location.pathname, currentPath);
    }); // To track the subsequent pageviews
  }, [history, trackPageView]);

  return (
    <Route
      {...rest}
      render={() => component}
    ></Route>
  );
}

export default App;
