import React, { useCallback, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import Variables, { getMinWidthSm } from 'Variables.styles';
import giftCard from 'assets/svg/gift-card.svg';
import { Trans } from 'react-i18next';
import Button from 'common/components/Button/Button';
import { useHistory, useLocation } from 'react-router-dom';
import { useCakeSelector } from 'app/rootReducer';
import { MdClose } from 'react-icons/md';
import { reloadUser } from 'user/userSlice';
import { useDispatch } from 'react-redux';
import { isLoggedInSelector } from 'user/userSelector';
import sdk from 'sdk';
import { getVoucherState, getVoucherInfoForPromo } from './VoucherSelector';
import { setVoucherCodeInLocal } from './voucherSlice';

const useStyles = createUseStyles<string, { showRegisterLoginButtons: boolean }>({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: (({ showRegisterLoginButtons }) => (showRegisterLoginButtons ? '20px' : '0px')),
    padding: '16px 24px',
    backgroundColor: 'white',
    boxShadow: Variables.primaryBoxShadow,
    borderRadius: Variables.small,
    fontSize: '1.6rem',
    fontWeight: 500,
    minHeight: '65px',
  },
  iconAndContent: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    alignItems: 'center',
  },
  giftIcon: {
    maxWidth: '20px',
    maxHeight: '20px',
  },
  content: {
    width: '100%',
  },
  action: {
    float: 'right',
    display: 'flex',
  },
  button: {
    width: '100px',
  },
  highlighted: {
    display: 'inline',
    color: Variables.electricBerry,
  },
  closeButton: {
    cursor: 'pointer',
  },
  closeButtonDesktop: {
    display: 'none',
  },
  closeButtonMobile: {
    display: 'block',
  },
  [getMinWidthSm]: {
    wrapper: {
      flexDirection: 'row',
    },
    closeButtonDesktop: {
      display: 'block',
    },
    closeButtonMobile: {
      display: 'none',
    },
  },
});

type BannerType = 'signup' | 'login' | 'pending' | 'redeemed';

const FaceItBanner = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { voucherCode, isLoading } = useCakeSelector(getVoucherState);
  const { status, claimedValue, voucherId, promoName } = useCakeSelector(getVoucherInfoForPromo);
  const isRazerPromo = promoName.toLowerCase().includes('razer');
  const [bannerType, setBannerType] = useState<BannerType>('signup');
  const showRegisterLoginButtons = ['signup', 'login'].includes(bannerType);
  const classes = useStyles({ showRegisterLoginButtons });
  const [displayValue, setDisplayValue] = useState('');
  const showCloseButton = bannerType === 'redeemed';
  const isLoggedIn = useCakeSelector(isLoggedInSelector);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(setVoucherCodeInLocal({ code: '' }));
    }
  }, [dispatch, isLoggedIn]);

  const updateBanner = useCallback(() => {
    // if voucher is claimed
    if (claimedValue && isLoggedIn) {
      if (status === 'LOCKED') {
        setBannerType('pending');
        setDisplayValue(claimedValue);
      } else if (status === 'REDEEMED') {
        setBannerType('redeemed');
        setDisplayValue(claimedValue);
      }
    } else if (!isLoggedIn) {
      // if voucher is yet to be claimed
      // eslint-disable-next-line no-lonely-if
      if (location.pathname === '/login') {
        setBannerType('signup');
        setDisplayValue('');
      } else {
        setBannerType('login');
        setDisplayValue('');
      }
    }
  }, [location.pathname, status, claimedValue, isLoggedIn]);

  const onCloseBanner = async () => {
    try {
      await sdk.VoucherInfoApi.dismiss(voucherId);
      await dispatch(reloadUser());
    } catch (err) {
      const { message } = err;
      window.cakepool.showAlert('error', <span><Trans>Error</Trans>: <Trans>{message}</Trans></span>);
    }
  };

  useEffect(() => {
    updateBanner();
  }, [voucherCode, updateBanner, claimedValue]);

  if (isLoading) {
    return null;
  }

  if (!voucherCode && status === '') {
    return null;
  }

  if (isRazerPromo) {
    return null;
  }

  const bannerComponents = {
    login: <div><Trans>Complete your signup and identity verification to claim your DFI prize from FACEIT</Trans></div>,
    signup: <div><Trans>Login to your account to claim your DFI prize from FACEIT</Trans></div>,
    pending: <div><Trans>Complete signup and verify your identity to receive <div className={classes.highlighted}>${{ displayValue }}</div> win from FACEIT</Trans></div>,
    redeemed: <div><Trans>You have received <div className={classes.highlighted}>${{ displayValue }}</div></Trans></div>,
  };

  const closeButton = <div className={classes.closeButton} onClick={onCloseBanner}>
    <MdClose size={24}/>
  </div>;

  return (
    <div className={classes.wrapper}>
      <div className={classes.iconAndContent}>
        <img src={giftCard} className={classes.giftIcon} alt='gift-card-icon'/>
        <div className={classes.content}>
          { bannerComponents[bannerType] }
        </div>
        { showCloseButton && <div className={classes.closeButtonMobile}>{ closeButton }</div> }
      </div>
      {
        showRegisterLoginButtons
          && <div className={classes.action}>
          <Button primary medium className={classes.button} onClick={() => history.push('/register')}>Sign Up</Button>
          <Button medium className={classes.button} onClick={() => history.push('/login')}>Login</Button>
        </div>
      }
      { showCloseButton && <div className={classes.closeButtonDesktop}>{ closeButton }</div> }
    </div>
  );
};

export default FaceItBanner;
