import appConfig from 'config/app';
import mixpanel, { Dict } from 'mixpanel-browser';

export const MIXEDPANEL_TRACKED_ROUTES = {
  '/register': {
    key: 'Sign Up Page Viewed',
    value: {
      sign_up_page_viewed: true,
    },
  },
  '/me/verify-account': {
    key: 'KYC Viewed',
    value: {
      kyc_page_viewed: true,
    },
  },
  '/me/security': {
    key: '2FA Viewed',
    value: {
      '2fa_page_viewed': true,
    },
  },
  '/staking': {
    key: 'Staking Page Viewed',
    value: {
      stake_coin_page_viewed: true,
    },
  },
  '/welcome': {
    key: 'Welcome Page Viewed',
    value: {
      welcome_page_viewed: true,
    },
  },
  '/elite': {
    key: 'cakeELITE Page Viewed',
    value: {
      elite_page_viewed: true,
    },
  },
  '/me/elite': {
    key: 'cakeELITE Settings Page Viewed',
    value: {
      elite_settings_page_viewed: true,
    },
  },
};

interface MixpanelTrackProperties {
  type: 'track';
  event: string;
  properties?: Dict;
}

interface MixpanelPeopleProperties {
  type: 'people_set' | 'people_set_once';
  event?: never;
  properties?: Dict;
}

interface MixpanelIdentifyProperties {
  type: 'identify';
  event?: never;
  properties: string;
}

interface MixpanelResetProperties {
  type: 'reset';
  event?: never;
  properties?: never;
}

type MixpanelProperties = MixpanelTrackProperties | MixpanelPeopleProperties | MixpanelIdentifyProperties | MixpanelResetProperties;

export function trackMixPanel({ type, event, properties }: MixpanelProperties) {
  const IS_PRODUCTION = process.env.NODE_ENV === 'production';
  if (!appConfig.MIXPANELKEY) {
    if (!IS_PRODUCTION) {
      // eslint-disable-next-line no-console
      console.debug('Mixpanel key is not defined');
    }
    return;
  }

  // note: this is just to follow other tracking methods
  // also, we are forcing here `as XXX` as TS is crashing (even though it is properly inferred)
  try {
    switch (type) {
      case 'track':
        mixpanel.track(event, properties as Dict);
        break;
      case 'people_set_once':
        mixpanel.people.set_once(properties as Dict);
        break;
      case 'people_set':
        mixpanel.people.set(properties as Dict);
        break;
      case 'identify':
        mixpanel.identify(properties as string);
        break;
      case 'reset':
        mixpanel.reset();
        break;
      default:
        break;
    }
  } catch (error) {
    if (!IS_PRODUCTION) {
      // eslint-disable-next-line no-console
      console.error(`Failed to ${type}: ${event} with properties ${JSON.stringify(properties)}`);
    }
  }
}
