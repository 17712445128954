import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { ModalHeader, ModalContent } from 'modals/components';
import { useCakeSelector } from 'app/rootReducer';
import { getCurrentLessonStack, getUserClaimedStatus } from 'learn-earn/learnEarnSelector';
import confetti from 'assets/svg/learnEarn/confetti.svg';
import modalStyles from 'common/styles/Modal.styles';
import Variables from 'Variables.styles';
import { useDispatch } from 'react-redux';
import { updateUserSavedProgress } from 'learn-earn/learnEarnSlice';
import { getRewardLabel } from 'learn-earn/learnEarnUtils';
import { InlineShareButtons } from 'sharethis-reactjs';

const useStyles = createUseStyles({
  ...modalStyles,
  completedContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '30px',
  },
  confettiImage: {
    width: '80px',
  },
  socialContainer: {
    marginTop: '60px',
    marginBottom: '80px',
  },
  socialItem: {
    border: Variables.primaryBorder,
    borderRadius: '5px',
    width: '60px',
    height: '60px',
    cursor: 'pointer',
  },
});

const textSources = {
  approvedAndClaimed: {
    congratsText: 'Congratulations on completing the lessons and earning $1 in {{rewardLabel}}. You can now find your earnings in your account balances page',
    shareText: 'Why not share the good news with your friends and/or followers? Rewards are limited, so act fast!',
  },
  unverified: {
    congratsText: 'Congratulations on completing the lessons and earning $1 in {{rewardLabel}}. You can find your earnings in your account balances page after completing account verification.',
    shareText: 'Why not share the good news with your friends and/or followers? Rewards are limited, so act fast!',
  },
  ineligible: {
    congratsText: 'Congratulations on completing the lessons!',
    shareText: 'Why not share the good news with your friends and/or followers?',
  },
};

const sharethisMessage = {
  eligible: 'I just completed this crypto Learn & Earn course on Bake and earned $1 in crypto for free! Try it out with this link.',
  ineligible: 'I just completed this crypto learn course on Bake, try it out with this link!',
};

let origin = '';
if (window.location.hostname === 'localhost') {
  origin = window.location.host;
} else {
  origin = window.location.hostname;
}

const baseConfig: any = {
  alignment: 'center', // alignment of buttons (left, center, right)
  color: 'white', // set the color of buttons (social, white)
  enabled: true, // show/hide buttons (true, false)
  font_size: 16, // font size for the buttons
  labels: 'null', // button labels (cta, counts, null)
  language: 'en', // which language to use (see LANGUAGES)
  networks: [ // which networks to include (see SHARING NETWORKS)
    'facebook',
    'twitter',
    'telegram',
    'linkedin',
    'vk',
    'email',
    'sharethis',
    'whatsapp',
    'wechat',
    'line',
  ],
  padding: 16, // padding within buttons (INTEGER)
  radius: 4, // the corner radius on each button (INTEGER)
  show_total: false,
  size: 50, // the size of each button (INTEGER)
  url: `https://${origin}/learn`, // (defaults to current url)
};

interface LessonModalCompletedProps {
  closeModal: () => void;
  headerTitle: string;
}

const LessonModalCompleted = ({ closeModal, headerTitle }: LessonModalCompletedProps) => {
  const classes = useStyles();
  const claimStatus = useCakeSelector(getUserClaimedStatus());
  const { coinId, rewardType } = useCakeSelector(getCurrentLessonStack);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { details: userDetails } = useCakeSelector(state => state.user);

  const rewardLabel = getRewardLabel(coinId, rewardType);

  useEffect(() => {
    dispatch(updateUserSavedProgress({ lessonIndex: 1, partIndex: 1, modal: 'WELCOME' }));
  }, [dispatch]);

  const textSource = (() => {
    if (userDetails?.kycFullStatus === 'APPROVED' && claimStatus) return textSources.approvedAndClaimed;
    if (userDetails?.kycFullStatus === 'APPROVED' && !claimStatus) return textSources.ineligible;
    return textSources.unverified;
  })();

  const config = {
    ...baseConfig,
    title: !claimStatus ? t(sharethisMessage.ineligible) : t(sharethisMessage.eligible),
  };

  // loads sharethis on first load
  useEffect(() => {
    try {
      window.__sharethis__?.initialize(); // eslint-disable-line
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('ShareThis', error);
    }
  }, []);

  return (
    <>
      <ModalHeader title={headerTitle} onClose={closeModal}/>
      <ModalContent>
        <div className={`${classes.contentMini} ${classes.completedContainer}`}>
          <img src={confetti} className={classes.confettiImage} alt="confetti"/>
          <div>{t(textSource.congratsText, { rewardLabel })}</div>
          <div><Trans i18nKey={textSource.shareText}>{textSource.shareText}</Trans></div>
        </div>
        <div className={classes.socialContainer}>
          <InlineShareButtons config={config}/>
        </div>
      </ModalContent>
    </>
  );
};

export default LessonModalCompleted;
